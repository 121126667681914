import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import UpdateListingStatus from "./UpdateListingStatus";
import ExportTableOptions from "./ExportTableOptions";
import AddCategory from "./AddCategory";
import ViewReview from "./ViewReview";
import UpdateCategoryStatus from "./UpdateCategoryStatus";
import UpdateListingRank from "./UpdateListingRank";
import IconGallery from "./IconGallery";
import AddLabel from "./AddLabel";
import SendEmail from "./SendEmail";
import DisplayIframe from "./DisplayIframe";
import AIReview from "./AIReview";
import UpdateReviewCount from "./UpdateReviewCount";

const EditMapAddress = lazy(() => import("./EditMapAddress"));
const DeleteUser = lazy(() => import("./DeleteUser"));
const AddPayment = lazy(() => import("./AddPayment"));
const HandleStatus = lazy(() => import("./HandleStatus"));
const DisableModal = lazy(() => import("./DisableModal"));
const ChildrenSeats = lazy(() => import("./ChildrenSeats"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const UpdateBookingBillingAddress = lazy(
  () => import("./UpdateBookingBillingAddress")
);

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  DELETE_USER: <DeleteUser />,
  ADD_PAYMENT: <AddPayment />,
  HANDLE_STATUS: <HandleStatus />,
  DISABLE_DRIVER: <DisableModal />,
  CHILDREN_SEATS: <ChildrenSeats />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  UPDATE_BOOKING_BILLING_ADDRESS: <UpdateBookingBillingAddress />,
  EDIT_ADDRESS: <EditMapAddress />,
  UPDATE_LISTING_STATUS: <UpdateListingStatus />,
  EXPORT_TABLE_OPTIONS: <ExportTableOptions />,
  ADD_CATEGORY: <AddCategory />,
  VIEW_REVIEW: <ViewReview />,
  UPDATE_CATEGORY_STATUS: <UpdateCategoryStatus />,
  UPDATE_LISTING_RANK: <UpdateListingRank />,
  UPDATE_REVIEW_COUNT: <UpdateReviewCount />,
  ICON_GALLERY: <IconGallery />,
  LABEL: <AddLabel />,
  SEND_EMAIL: <SendEmail />,
  DISPLAY_IFRAME: <DisplayIframe />,
  AI_REVIEW: <AIReview />,
};
