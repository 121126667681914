import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { modalActions } from "redux/slices/modal";
import { Box, TextField, Typography } from "@mui/material";
import Button from "components/atoms/Button";
import ListingService from "services/listings.service";

export default function UpdateReviewCount() {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.modal.data);
  const [reviewCount, setReviewCount] = useState(data.randomCount || 0);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await ListingService.updateReviewCount(data._id, reviewCount);
      dispatch(modalActions.closeModal());
    } catch (error) {
      console.error("Error updating review count:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Update Review Count
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="number"
          label="Review Count"
          value={reviewCount}
          onChange={(e) => setReviewCount(Number(e.target.value))}
          margin="normal"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button
            variant="outlined"
            onClick={() => dispatch(modalActions.closeModal())}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Update
          </Button>
        </Box>
      </form>
    </Box>
  );
}
